import React, { useMemo, useCallback } from 'react';
import { motion } from 'framer-motion';

const TwinklingBackground: React.FC = () => {
  const stars = useMemo(() => 
    Array.from({ length: 100 }, (_, i) => ({
      id: i,
      size: Math.random() * 2 + 1,
      left: Math.random() * 100,
      top: Math.random() * 100,
      animationDuration: Math.random() * 3 + 1,
      maxOpacity: Math.random() * 0.5 + 0.5,
    })),
    []
  );

  const animate = useCallback((maxOpacity: number) => ({
    opacity: [0, maxOpacity, 0],
    scale: [0.5, 1, 0.5],
  }), []);

  return (
    <div className="fixed inset-0 overflow-hidden pointer-events-none bg-black bg-opacity-50">
      {stars.map((star) => (
        <motion.div
          key={star.id}
          className="absolute bg-white rounded-full"
          style={{
            width: `${star.size}px`,
            height: `${star.size}px`,
            left: `${star.left}%`,
            top: `${star.top}%`,
          }}
          animate={animate(star.maxOpacity)}
          transition={{
            duration: star.animationDuration,
            repeat: Infinity,
            repeatType: 'reverse',
          }}
        />
      ))}
    </div>
  );
};

export default React.memo(TwinklingBackground);