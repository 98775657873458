import React from 'react';
import { useAuth } from '../contexts/AuthContext';

interface AuthButtonProps {
  onAuthChange: () => void;
}

const AuthButton: React.FC<AuthButtonProps> = ({ onAuthChange }) => {
  const { user, signIn, signOut } = useAuth();

  const handleAuth = async () => {
    if (user) {
      await signOut();
    } else {
      await signIn();
    }
    onAuthChange();
  };

  return (
    <button
      onClick={handleAuth}
      className="bg-white text-purple-800 px-3 py-1 sm:px-4 sm:py-2 rounded-full hover:bg-purple-100 transition-colors text-sm sm:text-base whitespace-nowrap"
    >
      {user ? 'Sign Out' : 'Sign In'}
    </button>
  );
};

export default AuthButton;