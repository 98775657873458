import React, { useEffect, useState } from 'react';
import { motion, PanInfo, useAnimation } from 'framer-motion';

interface CardProps {
  question: string;
  onSwipe: (direction: 'left' | 'right') => void;
  isFavorited: boolean;
  size: { width: string; height: string };
  cardNumber: number;
  totalCards: number;
}

const Card: React.FC<CardProps> = ({ question, onSwipe, isFavorited, size, cardNumber, totalCards }) => {
  const controls = useAnimation();
  const [rotation] = useState(Math.random() * 10 - 5); // Random rotation between -5 and 5 degrees

  const handleDragEnd = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    const threshold = 100;
    const horizontalSwipe = Math.abs(info.offset.x) > Math.abs(info.offset.y);

    if (horizontalSwipe) {
      if (info.offset.x > threshold) {
        controls.start({ x: '100%', opacity: 0, transition: { duration: 0.2 } });
        setTimeout(() => onSwipe('right'), 200);
      } else if (info.offset.x < -threshold) {
        controls.start({ x: '-100%', opacity: 0, transition: { duration: 0.2 } });
        setTimeout(() => onSwipe('left'), 200);
      } else {
        controls.start({ x: 0, y: 0, scale: 1, transition: { type: 'spring', stiffness: 500, damping: 30 } });
      }
    } else {
      controls.start({ x: 0, y: 0, scale: 1, transition: { type: 'spring', stiffness: 500, damping: 30 } });
    }
  };

  useEffect(() => {
    controls.start({
      x: 0,
      y: 0,
      opacity: 1,
      scale: 1,
      rotateY: 0,
      transition: { duration: 0.5, type: 'spring', stiffness: 300, damping: 20 }
    });
  }, [controls, question]);

  const isLongQuestion = question.length > 100;

  return (
    <motion.div
      className="absolute inset-0 rounded-2xl shadow-lg flex items-center justify-center cursor-grab active:cursor-grabbing overflow-hidden"
      style={{
        width: size.width,
        height: size.height,
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22)',
        transform: `rotate(${rotation}deg)`,
        backgroundImage: 'linear-gradient(135deg, #d4af37 0%, #f2d272 50%, #d4af37 100%)',
      }}
      drag={true}
      dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
      dragElastic={0.7}
      dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
      onDragEnd={handleDragEnd}
      initial={{ scale: 0.8, opacity: 0, rotateY: -90 }}
      animate={controls}
      exit={{ scale: 0.8, opacity: 0, rotateY: 90, transition: { duration: 0.3 } }}
      whileDrag={{ scale: 1 }}
    >
      <div className="absolute top-3 left-3 sm:top-5 sm:left-5 z-20">
        <span className="text-xs sm:text-sm font-semibold bg-white bg-opacity-20 px-2 py-1 rounded-full">
          {cardNumber} / {totalCards}
        </span>
      </div>
      <div className="p-6 sm:p-8 md:p-10 w-full h-full flex items-center justify-center">
        <p className={`font-serif italic font-bold text-gray-800 text-center z-10 relative mx-4 my-2 break-words
          ${isLongQuestion 
            ? 'text-xs sm:text-sm md:text-base lg:text-sm xl:text-base' 
            : 'text-base sm:text-lg md:text-xl lg:text-lg xl:text-xl'
          }`}
          style={{ overflowWrap: 'break-word' }}
        >
          {question}
        </p>
      </div>
      {isFavorited && (
        <div className="absolute top-3 right-3 sm:top-5 sm:right-5 z-20">
          <motion.span
            className="text-xl sm:text-2xl"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 500, damping: 15 }}
          >
            ❤️
          </motion.span>
        </div>
      )}
    </motion.div>
  );
};

export default Card;