import React from 'react';
import { Link } from 'react-router-dom';

const AboutPage: React.FC = () => {
  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-8 text-white">
      <h1 className="text-4xl font-bold mb-6">About Our Magic Within</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Background</h2>
        <div className="bg-white bg-opacity-10 p-6 rounded-lg">
          <p className="mb-4">
            <strong>Who are you in relation to others in your life? What makes you uniquely magical?</strong>
          </p>
          <p className="mb-4">
            While all people have far more similarities than differences, it is ESSENTIAL to finding your unique YOU-SHAPED HOLES in the world to understand in what ways people's minds diverge, and what makes your mind unique.
          </p>
          <p className="mb-4">
            To do this, you must map out the landscape of human cognitive diversity. But how do you do this?
          </p>
          <p className="mb-4">
            And as you map this landscape out, how do you begin to understand how you fit within that landscape?
          </p>
          <p className="mb-4">
            And ultimately, how do you learn what your superpowers are, and how to coordinate + collaborate effectively with your friends, family, and colleagues, who have different superpowers?
          </p>
          <p className="mb-4">
            You start by asking questions that reveal how beautifully different and uniquely magical we all are.
          </p>
          <p className="text-2xl font-semibold mt-6 italic">
            Welcome to "Our Magic Within" ✨🪄
          </p>
        </div>
      </section>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Rules</h2>
        <div className="bg-white bg-opacity-10 p-6 rounded-lg">
          <p className="mb-4">The rules of this game are simple:</p>
          <ul className="list-disc list-inside space-y-2">
            <li>Form a group of up to, say, 6. It works well with just two people too.</li>
            <li>Ask a question to the whole group for possibly everyone to answer.</li>
            <li>Encourage reactions to individual answers and enabling organic conversation.</li>
            <li>Once people are ready to move on, ask the next question.</li>
            <li>Rinse and repeat until you're ready to stop playing.</li>
          </ul>
        </div>
      </section>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Saving Your Favorites</h2>
        <div className="bg-white bg-opacity-10 p-6 rounded-lg">
          <p className="mb-4">
            Want to keep track of your favorite questions? Sign in to your account, and you'll be able to save and revisit your favorite cards anytime. Your favorites will be synced across devices, allowing you to access them wherever you go.
          </p>
          <p>
            Don't have an account yet? It's quick and easy to sign up. Just click the "Sign In" button in the top right corner to get started.
          </p>
        </div>
      </section>
      
      <div className="mt-8">
        <Link to="/" className="bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition-colors">
          Back to Card Deck
        </Link>
      </div>
    </div>
  );
};

export default AboutPage;