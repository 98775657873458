import React from 'react';
import { useNavigate } from 'react-router-dom';

interface FavoritedCardsProps {
  favoritedQuestions: string[];
}

const FavoritedCards: React.FC<FavoritedCardsProps> = ({ favoritedQuestions }) => {
  const navigate = useNavigate();

  const handleBackToDeck = () => {
    navigate('/');
  };

  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold mb-6 text-center">Your Favorited Cards</h2>
      {favoritedQuestions.length === 0 ? (
        <p className="text-center text-xl">You haven't favorited any cards yet.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {favoritedQuestions.map((question, index) => (
            <div key={index} className="bg-white bg-opacity-10 p-4 rounded-lg shadow-lg flex items-center justify-center min-h-[150px]">
              <p className="text-lg text-center">{question}</p>
            </div>
          ))}
        </div>
      )}
      <div className="mt-8 text-center">
        <button
          onClick={handleBackToDeck}
          className="bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition-colors"
        >
          Back to Card Deck
        </button>
      </div>
    </div>
  );
};

export default FavoritedCards;