import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBQ4aT77BebfUCIFPQDCmmfWcOREBJEIlU",
    authDomain: "magic-205ea.firebaseapp.com",
    projectId: "magic-205ea",
    storageBucket: "magic-205ea.appspot.com",
    messagingSenderId: "118660060410",
    appId: "1:118660060410:web:f9b346e8ea794f8590e543"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);