import React from 'react';
import { motion } from 'framer-motion';

interface LoginPromptProps {
  onClose: () => void;
  onLogin: () => void;
}

const LoginPrompt: React.FC<LoginPromptProps> = ({ onClose, onLogin }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      className="fixed bottom-4 left-4 right-4 bg-white rounded-lg shadow-lg p-4 text-purple-800"
    >
      <p className="mb-2">Save your hearted cards by creating an account!</p>
      <div className="flex justify-end space-x-2">
        <button
          onClick={onClose}
          className="px-3 py-1 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
        >
          Maybe later
        </button>
        <button
          onClick={onLogin}
          className="px-3 py-1 bg-purple-600 text-white rounded hover:bg-purple-700 transition-colors"
        >
          Sign In
        </button>
      </div>
    </motion.div>
  );
};

export default LoginPrompt;