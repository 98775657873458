import React, { useState, useEffect, useRef } from 'react';
import Card from './Card';
import { questions } from '../data/questions';
import { useAuth } from '../contexts/AuthContext';
import LoginPrompt from './LoginPrompt';
import { AnimatePresence, motion } from 'framer-motion';

interface CardDeckProps {
  favoritedQuestions: string[];
  handleFavorite: (question: string) => void;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
}

const CardDeck: React.FC<CardDeckProps> = ({ 
  favoritedQuestions, 
  handleFavorite, 
  currentIndex, 
  setCurrentIndex 
}) => {
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [loginPromptDismissCount, setLoginPromptDismissCount] = useState(0);
  const [cardSize, setCardSize] = useState({ width: '100%', height: '100%' });
  const { user, signIn } = useAuth();
  const cardContainerRef = useRef<HTMLDivElement>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateCardSize = () => {
      if (cardContainerRef.current) {
        const containerRect = cardContainerRef.current.getBoundingClientRect();
        const maxSize = Math.max(300, Math.min(containerRect.width - 40, containerRect.height * 0.7));
        setCardSize({ width: `${maxSize}px`, height: `${maxSize}px` });
      }
    };

    updateCardSize();
    window.addEventListener('resize', updateCardSize);
    return () => window.removeEventListener('resize', updateCardSize);
  }, []);

  useEffect(() => {
    audioRef.current = new Audio(process.env.PUBLIC_URL + '/swipe.mp3');
    audioRef.current.addEventListener('error', (e) => {
      console.error('Error loading audio:', e);
    });
  }, []);

  useEffect(() => {
    if (!user && favoritedQuestions.length > 0) {
      if (loginPromptDismissCount === 0 && favoritedQuestions.length === 2) {
        setShowLoginPrompt(true);
      } else if (loginPromptDismissCount === 1 && favoritedQuestions.length >= 10) {
        setShowLoginPrompt(true);
      } else if (loginPromptDismissCount === 2 && favoritedQuestions.length >= 25) {
        setShowLoginPrompt(true);
      }
    }
  }, [favoritedQuestions, user, loginPromptDismissCount]);

  const playSwipeSound = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.volume = 0.05;
      audioRef.current.play().catch(error => {
        console.error('Error playing audio:', error);
      });
    }
  };

  const handleFavoriteClick = () => {
    handleFavorite(questions[currentIndex]);
  };

  const moveToNextCard = () => {
    playSwipeSound();
    setCurrentIndex((prevIndex) => (prevIndex + 1) % questions.length);
  };

  const moveToPreviousCard = () => {
    playSwipeSound();
    setCurrentIndex((prevIndex) => (prevIndex - 1 + questions.length) % questions.length);
  };

  const handleLoginPromptClose = () => {
    setShowLoginPrompt(false);
    setLoginPromptDismissCount((prevCount) => prevCount + 1);
  };

  const generateShareImage = async () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Set canvas size (adjust as needed)
    const size = 1200;
    canvas.width = size;
    canvas.height = size;

    // Background gradient
    const bgGradient = ctx.createLinearGradient(0, 0, 0, size);
    bgGradient.addColorStop(0, '#4a0e4e');
    bgGradient.addColorStop(1, '#170a41');
    ctx.fillStyle = bgGradient;
    ctx.fillRect(0, 0, size, size);

    // Golden gradient for the question box
    const boxSize = size * 0.7; // Reduced size to prevent overlap
    const boxX = (size - boxSize) / 2;
    const boxY = size * 0.15; // Moved up slightly
    const goldGradient = ctx.createLinearGradient(boxX, boxY, boxX + boxSize, boxY + boxSize);
    goldGradient.addColorStop(0, '#d4af37');
    goldGradient.addColorStop(0.5, '#f2d272');
    goldGradient.addColorStop(1, '#d4af37');

    // Draw rounded rectangle for question box
    ctx.fillStyle = goldGradient;
    roundRect(ctx, boxX, boxY, boxSize, boxSize, 20);

    // Text
    ctx.fillStyle = 'white';
    ctx.textAlign = 'center';
    ctx.font = 'bold 48px Arial';
    ctx.fillText('How would you answer this question?', size / 2, size * 0.1);

    // Question text (larger, black, bold, italic, and centered)
    ctx.fillStyle = 'black';
    ctx.font = 'italic bold 60px serif'; // Increased font size and added italic
    const questionLines = wrapText(ctx, questions[currentIndex], boxSize - 100); // Adjusted for larger text
    const lineHeight = 80; // Increased line height
    const totalTextHeight = questionLines.length * lineHeight;
    const textStartY = boxY + (boxSize - totalTextHeight) / 2 - 40; // Center vertically and move up slightly

    questionLines.forEach((line, index) => {
      ctx.fillText(line, size / 2, textStartY + index * lineHeight);
    });

    // Add thinking emoji
    ctx.font = '72px Arial'; // Adjust size as needed
    ctx.fillText('🤔', size / 2, textStartY + totalTextHeight + 80); // Position below the question text

    ctx.fillStyle = 'white';
    ctx.font = '32px Arial';
    ctx.fillText('Explore more questions at', size / 2, size * 0.92);
    ctx.font = 'bold 36px Arial';
    ctx.fillText('ourmagicwithin.netlify.app', size / 2, size * 0.97);

    // Convert to data URL and trigger download
    const dataUrl = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = 'magic-within-card.png';
    link.href = dataUrl;
    link.click();
  };

  // Helper function to draw rounded rectangles
  function roundRect(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, radius: number) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + height, radius);
    ctx.arcTo(x + width, y + height, x, y + height, radius);
    ctx.arcTo(x, y + height, x, y, radius);
    ctx.arcTo(x, y, x + width, y, radius);
    ctx.closePath();
    ctx.fill();
  }

  // Helper function to wrap text (update if necessary for longer lines)
  function wrapText(ctx: CanvasRenderingContext2D, text: string, maxWidth: number): string[] {
    const words = text.split(' ');
    const lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = ctx.measureText(currentLine + " " + word).width;
      if (width < maxWidth) {
        currentLine += " " + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines;
  }

  return (
    <div className="w-full max-w-lg mx-auto px-4 flex flex-col h-[80vh]">
      <div className="flex-grow flex flex-col justify-start items-center pt-4">
        <div ref={cardContainerRef} className="relative w-full flex justify-center items-center" style={{ minHeight: '300px' }}>
          <div ref={cardRef} style={{ width: cardSize.width, height: cardSize.height, position: 'relative', minWidth: '300px', minHeight: '300px' }}>
            <AnimatePresence initial={false}>
              <Card
                key={currentIndex}
                question={questions[currentIndex]}
                onSwipe={(direction) => {
                  playSwipeSound();
                  if (direction === 'right') {
                    moveToNextCard();
                  } else {
                    moveToPreviousCard();
                  }
                }}
                isFavorited={favoritedQuestions.includes(questions[currentIndex])}
                size={cardSize}
                cardNumber={currentIndex + 1}
                totalCards={questions.length}
              />
            </AnimatePresence>
          </div>
        </div>
        <div className="mt-4 flex justify-center items-center space-x-4">
          <motion.button
            className="text-4xl focus:outline-none"
            onClick={moveToPreviousCard}
            aria-label="Previous card"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 400, damping: 17 }}
          >
            ⬅️
          </motion.button>
          <div className="flex flex-col items-center space-y-2">
            <button
              className="bg-pink-500 text-white px-4 py-2 rounded-full hover:bg-pink-600 transition-colors w-24 sm:w-32 flex items-center justify-center"
              onClick={handleFavoriteClick}
            >
              <span className="text-center">
                {favoritedQuestions.includes(questions[currentIndex]) ? 'Unfavorite' : 'Favorite'}
              </span>
            </button>
            <motion.button
              className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition-colors w-24 sm:w-32"
              onClick={generateShareImage}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Share
            </motion.button>
          </div>
          <motion.button
            className="text-4xl focus:outline-none"
            onClick={moveToNextCard}
            aria-label="Next card"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 400, damping: 17 }}
          >
            ➡️
          </motion.button>
        </div>
      </div>
      <AnimatePresence>
        {showLoginPrompt && loginPromptDismissCount < 3 && (
          <LoginPrompt
            onClose={handleLoginPromptClose}
            onLogin={() => {
              signIn();
              setShowLoginPrompt(false);
            }}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default CardDeck;