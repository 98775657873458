import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import CardDeck from './components/CardDeck';
import FavoritedCards from './components/FavoritedCards';
import AboutPage from './components/AboutPage';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import AuthButton from './components/AuthButton';
import TwinklingBackground from './components/TwinklingBackground';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from './firebase';

const Footer: React.FC = () => {
  const location = useLocation();

  if (location.pathname !== '/about') {
    return null;
  }

  return (
    <footer className="py-4 sm:py-6 px-4 sm:px-6 text-center text-sm text-purple-200 relative z-10">
      <p className="bg-black bg-opacity-20 inline-block px-3 sm:px-4 py-1 sm:py-2 rounded-full">
        Discover the magic within you. Made with love by Gary Sheng.
      </p>
    </footer>
  );
};

const AppContent: React.FC = () => {
  const [authChangeCounter, setAuthChangeCounter] = useState(0);
  const [favoritedQuestions, setFavoritedQuestions] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { user } = useAuth();

  useEffect(() => {
    const loadFavoritedQuestions = async () => {
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setFavoritedQuestions(docSnap.data().favoritedQuestions || []);
        }
      } else {
        const localFavorites = localStorage.getItem('favoritedQuestions');
        if (localFavorites) {
          setFavoritedQuestions(JSON.parse(localFavorites));
        }
      }
    };

    loadFavoritedQuestions();
  }, [user]);

  const handleFavorite = async (question: string) => {
    const newFavoritedQuestions = favoritedQuestions.includes(question)
      ? favoritedQuestions.filter((q) => q !== question)
      : [...favoritedQuestions, question];

    setFavoritedQuestions(newFavoritedQuestions);

    if (user) {
      await setDoc(doc(db, 'users', user.uid), {
        favoritedQuestions: newFavoritedQuestions,
      }, { merge: true });
    } else {
      localStorage.setItem('favoritedQuestions', JSON.stringify(newFavoritedQuestions));
    }
  };

  const handleAuthChange = () => {
    setAuthChangeCounter(prev => prev + 1);
  };

  return (
    <Router>
      <div className="App min-h-screen flex flex-col bg-gradient-to-br from-purple-900 via-indigo-900 to-blue-900 text-white relative overflow-hidden">
        <TwinklingBackground />
        <header className="py-2 sm:py-4 px-2 sm:px-6 flex flex-col sm:flex-row justify-between items-center relative z-10">
          <Link to="/" className="text-2xl sm:text-3xl md:text-4xl font-bold font-serif italic mb-2 sm:mb-0">
            Our Magic Within ✨
          </Link>
          <nav className="flex items-center space-x-2 sm:space-x-4 text-sm sm:text-base">
            <Link to="/about" className="text-white hover:text-purple-200 transition-colors">About</Link>
            <Link to="/favorites" className="text-white hover:text-purple-200 transition-colors">
              Favorites {favoritedQuestions.length > 0 && `(${favoritedQuestions.length})`}
            </Link>
            <AuthButton onAuthChange={handleAuthChange} />
          </nav>
        </header>
        <main className="flex-grow flex items-center justify-center p-4 relative z-10">
          <Routes>
            <Route 
              path="/" 
              element={
                <CardDeck 
                  key={authChangeCounter} 
                  favoritedQuestions={favoritedQuestions} 
                  handleFavorite={handleFavorite}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                />
              } 
            />
            <Route path="/favorites" element={<FavoritedCards favoritedQuestions={favoritedQuestions} />} />
            <Route path="/about" element={<AboutPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

const App: React.FC = () => (
  <AuthProvider>
    <AppContent />
  </AuthProvider>
);

export default App;